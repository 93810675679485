<template>
	<section id="contact" class="py-15" style="background-color: #3f5fb9">
		<ContactForm style="color: white" color="transparent" :dark="true" />
	</section>
</template>

<script>
export default {
	name: 'LandingContact',
	components: {
		ContactForm: () => import('@/components/main/ContactForm.vue')
	}
}
</script>
